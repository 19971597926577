<template>
  <article class="default-links" v-if="user_is_coordinator">
    <div class="site-wrapper rythm-v-l">
      <header class="page-header">
        <h1>Liens utiles</h1>
      </header>

      <section class="section--default-links">
        <div class="card shadow default-links-wrapper flex-item--100">
          <div class="flex-item--100">
            <p>
              Ces liens sont ajoutés automatiquement à une question lors de la réponse de l'expert.<br />
              L'expert peut modifier cette liste de lien pour chaque question.
            </p>
          </div>
          <form @submit.prevent="addNewDefaultLink" class="flex-row flex-align-bottom">
            <div class="flex-item--30">
              <div class="input-group">
                <label for="label">Texte du lien</label>
                <input type="text" name="label" id="new-link-label" v-model="newLink.label" required />
              </div>
            </div>
            <div class="flex-item--30">
              <div class="input-group">
                <label for="url">Adresse</label>
                <input type="url" name="url" id="new-link-url" v-model="newLink.url" required />
              </div>
            </div>
            <div class="flex-item--30">
              <button class="button--primary" type="submit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-plus"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>

                <span class="caption">Ajouter</span>
              </button>
            </div>
          </form>

          <div>
            <ul class="links-list" v-if="defaultLinks">
              <li v-for="link in defaultLinks">
                <div class="link-card">
                  <header>{{ link.label }}</header>
                  <a :href="link.url">{{ link.url }}</a>
                  <button class="button--link button-delete-link" @click="removeLink(link.link_id)" title="Supprimer le lien">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trash-2"
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </article>
</template>

<style lang="scss">
@import "@/scss/default-links.scss";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { HTTP } from "../http-common.js";

// Assignments component
export default {
  name: "DefaultLinks",
  props: {},

  data: function() {
    return {
      defaultLinks: [],
      newLink: {
        label: "",
        url: "",
      },
    };
  },

  computed: {
    // Current user data
    user() {
      return this.$store.state.userData;
    },

    // Is the user an admin? (role 4)
    user_is_admin: function() {
      return this.user.roles.indexOf(4) !== -1;
    },

    // Is the user a coordinator? (role 3)
    user_is_coordinator: function() {
      return this.user.roles.indexOf(3) !== -1;
    },

    // Is the user an expert? (role 2)
    user_is_expert: function() {
      return this.user.roles.indexOf(2) !== -1;
    },

    // Is the user an expert extended? (role 5)
    user_is_expert_ext: function() {
      return this.user.roles.indexOf(5) !== -1;
    },

    // Is a regular user?
    user_is_regular: function() {
      let r = this.user.roles.reduce((acc, val, i, roles) => acc + parseInt(val));
      return r < 2;
    },
  },

  methods: {
    // Récupère la liste des liens par défaut
    getDefaultLink: function() {
      this.$store
        .dispatch("GET_DEFAULT_LINKS", {})
        .then((defaultLinks) => {
          this.defaultLinks = defaultLinks;
        })
        .catch((error) => {
          console.error("GET_DEFAULT_LINKS", error);
        });
    },

    // Retire un lien de la liste
    removeLink: function(linkId) {
      this.$store
        .dispatch("DELETE_DEFAULT_LINKS", linkId)
        .then((success) => {
          this.getDefaultLink();
          // if (!success) {
          //   this.getDefaultLink();
          // } else {
          //   this.$toasted.global.appError({
          //     message: "<strong>Impossible de supprimer le lien&nbsp;!",
          //   });
          // }
        })
        .catch((error) => {
          this.getDefaultLink();
          this.$toasted.global.appError({
            message: "<strong>Erreur lors de la suppression du lien.</strong>",
          });
          console.error("DELETE_DEFAULT_LINKS", error);
        });
    },

    // Ajoute un nouveau lien par défaut
    addNewDefaultLink: function() {
      this.newLink.label = this.capitalizeFirstLetter(this.newLink.label);
      this.$store
        .dispatch("SAVE_DEFAULT_LINK", this.newLink)
        .then((success) => {
          if (success) {
            this.getDefaultLink();
            this.newLink = {
              label: "",
              url: "",
            };
          } else {
            this.$toasted.global.appError({
              message: "<strong>Impossible d'enregistrer le lien&nbsp;!",
            });
          }
        })
        .catch((error) => {
          console.error("SAVE_DEFAULT_LINKS", error);
        });
    },

    // Met une majuscule à la première lettre d'une phrase
    capitalizeFirstLetter: function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },

  /**
   * Mounted
   */
  mounted() {
    // Not for regular users
    if (this.user_is_regular) {
      this.$router.push("/dashboard");
    }

    this.getDefaultLink();
  },
};
</script>
