<template>
  <article class="assignments">
    <div class="site-wrapper rythm-v-l">
      <header class="page-header">
        <h1>Experts de garde</h1>
      </header>

      <section class="section--assignments-toolbar rythm-v">
        <div class="toolbar card flex-item-100" data-toolbar="assignments">
          <select name="select-period" class="vgt-select" v-model="period" @change="getAssignments">
            <option :value="selectedPeriod.id" v-for="(selectedPeriod, index) in periods" :key="`assignments_period_${index}`">{{ selectedPeriod.name }}</option>
          </select>

          <select name="select-year" class="vgt-select" v-model="year" @change="getAssignments">
            <option :value="selectedYear" v-for="(selectedYear, index) in years" :key="`assignments_year_${index}`">{{ selectedYear }}</option>
          </select>

          <div class="flex-item--25 flex-row flex-nowrap">
            <multiselect
              name="search"
              v-model="searchExpert"
              label="name"
              :options="multiSelectOptions"
              :close-on-select="true"
              placeholder="Filtrer sur un expert"
              :multiple="false"
              track-by="id"
              :searchable="true"
              :preselect-first="false"
              :clear-on-select="false"
              :preserve-search="false"
              :internal-search="true"
              selectLabel=""
              selectedLabel="Sélectionné"
              deselectLabel="Supprimer"
              tag-placeholder="Ajouter"
            >
            </multiselect>
          </div>
        </div>
      </section>

      <section class="section--assignments">
        <div class="card shadow assignements-table-wrapper flex-item--100">
          <vue-good-table
            v-if="rows.length"
            ref="assignmentsTable"
            :columns="columns"
            :rows="rows"
            styleClass="vgt-table striped"
            :select-options="{
              enabled: isCoordinator,
              selectOnCheckboxOnly: true,
              selectionText: 'ligne(s) sélectionnée(s)',
              clearSelectionText: 'Aucun',
              resetAfter: false,
            }"
            :search-options="{
              enabled: true,
              externalQuery: searchExpert,
              searchFn: handleSearch,
              // trigger: 'enter'
            }"
          >
            <!-- Actions liées à la sélection multiple -->
            <div slot="selected-row-actions">
              <!-- Expert primaire adultes -->
              <multiselect
                name="group-select-primary-expert-adults"
                v-model="groupAssignments.expert_primary_adults"
                label="name"
                :options="groupMultiSelectOptions"
                :close-on-select="true"
                placeholder="Expert principal adultes"
                :multiple="false"
                track-by="id"
                :searchable="true"
                :preselect-first="false"
                :clear-on-select="false"
                :preserve-search="false"
                :internal-search="true"
                selectLabel=""
                selectedLabel="Sélectionné"
                deselectLabel="Supprimer"
                tag-placeholder="Ajouter"
              >
              </multiselect>

              <!-- Expert(s) secondaire(s) adultes -->
              <multiselect
                name="group-select-secondary-expert-adults"
                v-model="groupAssignments.expert_secondary_adults"
                label="name"
                :options="groupMultiSelectOptions"
                :close-on-select="false"
                placeholder="Expert(s) secondaire(s) adultes"
                :multiple="true"
                track-by="id"
                :searchable="true"
                :preselect-first="false"
                :clear-on-select="false"
                :preserve-search="false"
                :internal-search="true"
                selectLabel=""
                selectedLabel="Sélectionné"
                deselectLabel="Supprimer"
                tag-placeholder="Ajouter"
              >
              </multiselect>

              <!-- Expert primaire enfants -->
              <multiselect
                name="group-select-primary-expert-children"
                v-model="groupAssignments.expert_primary_children"
                label="name"
                :options="groupMultiSelectOptions"
                :close-on-select="true"
                placeholder="Expert principal enfants"
                :multiple="false"
                track-by="id"
                :searchable="true"
                :preselect-first="false"
                :clear-on-select="false"
                :preserve-search="false"
                :internal-search="true"
                selectLabel=""
                selectedLabel="Sélectionné"
                deselectLabel="Supprimer"
                tag-placeholder="Ajouter"
              >
              </multiselect>

              <!-- Expert(s) secondaire(s) enfants -->
              <multiselect
                name="group-select-secondary-expert-children"
                v-model="groupAssignments.expert_secondary_children"
                label="name"
                :options="groupMultiSelectOptions"
                :close-on-select="false"
                placeholder="Expert(s) secondaire(s) enfants"
                :multiple="true"
                track-by="id"
              >
              </multiselect>

              <button type="button" class="button--primary" @click="groupChangeExpert">
                <!-- Enregistrer</button> -->
                <span class="caption">Affecter</span>
                <svg class="icon" role="img" xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none" viewBox="0 0 20 16">
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M1.17157 10.7716C1.92172 10.0214 2.93913 9.6 4 9.6H9.6C10.6609 9.6 11.6783 10.0214 12.4284 10.7716 13.1786 11.5217 13.6 12.5391 13.6 13.6V15.2C13.6 15.6418 13.2418 16 12.8 16 12.3582 16 12 15.6418 12 15.2V13.6C12 12.9635 11.7471 12.353 11.2971 11.9029 10.847 11.4529 10.2365 11.2 9.6 11.2H4C3.36348 11.2 2.75303 11.4529 2.30294 11.9029 1.85286 12.353 1.6 12.9635 1.6 13.6V15.2C1.6 15.6418 1.24183 16 .8 16 .358172 16 0 15.6418 0 15.2V13.6C0 12.5391.421428 11.5217 1.17157 10.7716zM6.8 1.6C5.47452 1.6 4.4 2.67452 4.4 4 4.4 5.32548 5.47452 6.4 6.8 6.4 8.12548 6.4 9.2 5.32548 9.2 4 9.2 2.67452 8.12548 1.6 6.8 1.6zM2.8 4C2.8 1.79086 4.59086 0 6.8 0 9.00914 0 10.8 1.79086 10.8 4 10.8 6.20914 9.00914 8 6.8 8 4.59086 8 2.8 6.20914 2.8 4zM16 4C16.4418 4 16.8 4.35817 16.8 4.8V9.6C16.8 10.0418 16.4418 10.4 16 10.4 15.5582 10.4 15.2 10.0418 15.2 9.6V4.8C15.2 4.35817 15.5582 4 16 4z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M12.8 7.2C12.8 6.75817 13.1582 6.4 13.6 6.4H18.4C18.8418 6.4 19.2 6.75817 19.2 7.2C19.2 7.64183 18.8418 8 18.4 8H13.6C13.1582 8 12.8 7.64183 12.8 7.2Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <template slot="table-column" slot-scope="props">
              <span v-if="props.column.field == 'actions'"></span>
              <span>{{ props.column.label }}</span>
            </template>

            <template slot="table-row" slot-scope="props">
              <!-- Expert primaire adultes -->
              <div class="flex-row flex-center-v" v-if="props.column.field == 'expert_primary_adults'">
                <multiselect
                  v-if="isCoordinator"
                  name="select-primary-expert-adults"
                  v-model="assignments[props.row.assignment_date].expert_primary_adults"
                  label="name"
                  :options="multiSelectOptions"
                  :close-on-select="true"
                  placeholder="Choisissez un expert"
                  :multiple="false"
                  track-by="id"
                  @input="changeExpert(props.row.assignment_date)"
                  :searchable="true"
                  :preselect-first="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  :internal-search="true"
                  selectLabel=""
                  selectedLabel="Sélectionné"
                  deselectLabel="Supprimer"
                  tag-placeholder="Ajouter"
                >
                </multiselect>
                <div v-else>
                  {{ assignments[props.row.assignment_date].expert_primary_adults.name }}
                </div>
              </div>

              <!-- Expert(s) secondaire(s) adultes -->
              <div class="flex-row flex-center-v" v-else-if="props.column.field == 'expert_secondary_adults'">
                <multiselect
                  v-if="isCoordinator"
                  name="select-secondary-expert-adults"
                  v-model="assignments[props.row.assignment_date].expert_secondary_adults"
                  label="name"
                  :options="multiSelectOptions"
                  :close-on-select="false"
                  placeholder="Choisissez un ou plusieurs experts"
                  :multiple="true"
                  track-by="id"
                  @input="changeExpert(props.row.assignment_date)"
                  :searchable="true"
                  :preselect-first="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  :internal-search="true"
                  selectLabel=""
                  selectedLabel="Sélectionné"
                  deselectLabel="Supprimer"
                  tag-placeholder="Ajouter"
                >
                </multiselect>
                <div v-else>
                  <div v-for="expert in assignments[props.row.assignment_date].expert_secondary_adults">
                    {{ expert.name }}
                  </div>
                </div>
              </div>

              <!-- Expert primaire enfants -->
              <div class="flex-row flex-center-v" v-else-if="props.column.field == 'expert_primary_children'">
                <multiselect
                  v-if="isCoordinator"
                  name="select-primary-expert-children"
                  v-model="assignments[props.row.assignment_date].expert_primary_children"
                  label="name"
                  :options="multiSelectOptions"
                  :close-on-select="true"
                  placeholder="Choisissez un expert"
                  :multiple="false"
                  track-by="id"
                  @input="changeExpert(props.row.assignment_date)"
                  :searchable="true"
                  :preselect-first="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  :internal-search="true"
                  selectLabel=""
                  selectedLabel="Sélectionné"
                  deselectLabel="Supprimer"
                  tag-placeholder="Ajouter"
                >
                </multiselect>
                <div v-else>
                  {{ assignments[props.row.assignment_date].expert_primary_children.name }}
                </div>
              </div>

              <!-- Expert(s) secondaire(s) adultes -->
              <div class="flex-row flex-center-v" v-else-if="props.column.field == 'expert_secondary_children'">
                <multiselect
                  v-if="isCoordinator"
                  name="select-secondary-expert-children"
                  v-model="assignments[props.row.assignment_date].expert_secondary_children"
                  label="name"
                  :options="multiSelectOptions"
                  :close-on-select="false"
                  placeholder="Choisissez un ou plusieurs experts"
                  :multiple="true"
                  track-by="id"
                  @input="changeExpert(props.row.assignment_date)"
                  :searchable="true"
                  :preselect-first="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  :internal-search="true"
                  selectLabel=""
                  selectedLabel="Sélectionné"
                  deselectLabel="Supprimer"
                  tag-placeholder="Ajouter"
                >
                </multiselect>
                <div v-else>
                  <div v-for="expert in assignments[props.row.assignment_date].expert_secondary_children">
                    {{ expert.name }}
                  </div>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </section>
    </div>
  </article>
</template>

<style lang="scss">
@import "@/scss/assignments.scss";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { HTTP } from "../http-common.js";

// Vue Good Table
import { VueGoodTable } from "vue-good-table";

//Multiselect
import Multiselect from "vue-multiselect";

// Assignments component
export default {
  name: "Assignments",
  props: {},

  components: { VueGoodTable, Multiselect },

  data: function() {
    return {
      experts: [],
      assignments: [],
      groupAssignments: {
        expert_primary_adults: "",
        expert_primary_children: "",
        expert_secondary_adults: [],
        expert_secondary_children: [],
      },
      rows: [],
      columns: [
        {
          label: "Date",
          // field: "assignment_date",
          field: "formated_assignment_date",
          sortable: false,
          tdClass: "",
          width: "200px",
        },
        {
          label: "Expert principal adultes",
          field: "expert_primary_adults",
          sortable: false,
          tdClass: "",
        },
        {
          label: "Expert(s) secondaire(s) adultes",
          field: "expert_secondary_adults",
          sortable: false,
          tdClass: "",
        },
        {
          label: "Expert principal enfants",
          field: "expert_primary_children",
          sortable: false,
          tdClass: "",
        },
        {
          label: "Expert(s) secondaire(s) enfants",
          field: "expert_secondary_children",
          sortable: false,
          tdClass: "",
        },
      ],
      year: null,
      period: null,
      periods: [
        { id: 1, name: "De Janvier à Juin" },
        { id: 2, name: "De Juillet à Décembre" },
      ],
      searchExpert: "",
    };
  },

  computed: {
    // Current user data
    user() {
      return this.$store.state.userData;
    },

    // Is a regular user?
    user_is_regular: function() {
      let r = this.user.roles.reduce((acc, val, i, roles) => acc + parseInt(val));
      return r < 2;
    },

    multiSelectOptions() {
      if (!this.experts) {
        return [];
      }
      let options = this.experts.map((expert) => {
        return { name: `${expert.firstname} ${expert.lastname}`, id: expert.id };
      });
      return options;
    },

    groupMultiSelectOptions() {
      let options = [...this.multiSelectOptions];
      options.unshift({ name: "Aucun", id: -1 });
      return options;
    },

    isCoordinator() {
      if (this.user.roles.includes(3)) {
        return true;
      }
      return false;
    },

    years() {
      let now = new Date();
      return [now.getFullYear(), now.getFullYear() + 1];
    },
  },

  methods: {
    // Fetch experts
    async getExperts() {
      let users = await this.$store.dispatch("GET_EXPERT_USERS");

      // Remove Coordinators & get only experts accepting on-call
      this.experts = users.filter(
        (u) =>
          // Expert (ext) + Coordinator OR Expert only
          ((u.roles.includes("3") && u.roles.includes("5")) || (!u.roles.includes("3") && u.roles.includes("5"))) && parseInt(u.accept_oncall) === 1
      );
    },

    getAssignments: function() {
      let year = this.year;
      let period = this.period;

      this.$store
        .dispatch("GET_ASSIGNMENTS", { year, period })
        .then((response) => {
          if (null !== response) {
            Object.entries(response).forEach((element) => {
              let date = new Date(element[1]["assignment_date"]);
              const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
              element[1]["formated_assignment_date"] = date.toLocaleDateString("fr-FR", options);
              element[1]["expert_primary_adults"] = element[1]["expert_primary_adults"] ? this.addExpertDetails(element[1]["expert_primary_adults"]) : "";
              element[1]["expert_primary_children"] = element[1]["expert_primary_children"] ? this.addExpertDetails(element[1]["expert_primary_children"]) : "";
              element[1]["expert_secondary_adults"] = element[1]["expert_secondary_adults"] ? element[1]["expert_secondary_adults"].map(this.addExpertDetails) : [];
              element[1]["expert_secondary_children"] = element[1]["expert_secondary_children"] ? element[1]["expert_secondary_children"].map(this.addExpertDetails) : [];
            });

            // this.assignments = response;
            this.assignments = { ...response };
            this.rows = Object.values(response);
          } else {
            this.$toasted.global.appInfo({
              message: `Cette période n'est pas accessible`,
            });
          }
        })
        .catch((error) => {
          console.error("GET_ASSIGNMENTS", error);
        });
    },

    /**
     * Add expert details to assignments
     *
     * @param {any} id
     * @return Object
     */
    addExpertDetails: function(id) {
      if (id === null || id === "-1") return null;
      let expert = this.experts.find((expert) => parseInt(expert.id) == parseInt(id));
      if (expert == "undefined" || typeof expert === "undefined") return null;

      return {
        id: id,
        name: expert.firstname + " " + expert.lastname,
      };
    },

    putAssignment: function(assignment) {
      this.$store.dispatch("PUT_ASSIGNMENT", assignment).then((success) => {
        if (!success) {
          this.$toasted.global.appError({
            message: "<strong>Impossible d'enregistrer l'affectation&nbsp;!",
          });
        }
      });
    },

    // On change la selection d'un expert primaire
    changeExpert: function(date) {
      let data = this.assignments[date];
      this.putAssignment(data);
    },

    groupChangeExpert: function(e) {
      let selectedLines = this.$refs["assignmentsTable"].selectedRows;
      let groupAssignments = this.groupAssignments;

      // On retire les propriétés vides de l'objet sauf si l'utilisateur a précisé aucun
      for (let key in groupAssignments) {
        //Les options aucun ont une valeur de -1
        if ((key == "expert_primary_adults" || key == "expert_primary_children") && typeof groupAssignments[key] == "object" && groupAssignments[key].id == -1) {
          groupAssignments[key] = "";
        } else if (Object.values(groupAssignments[key]).find((el) => el.id == -1)) {
          groupAssignments[key] = [];
        } else if (!groupAssignments[key] || groupAssignments[key].length === 0) {
          delete groupAssignments[key];
        }
      }

      selectedLines.forEach((selectedLine) => {
        let date = selectedLine.assignment_date;
        this.assignments[date] = { ...this.assignments[date], ...groupAssignments };

        this.changeExpert(date);
        this.clearGroupAssignments();
      });
    },

    clearGroupAssignments: function() {
      this.groupAssignments = {
        expert_primary_adults: "",
        expert_primary_children: "",
        expert_secondary_adults: [],
        expert_secondary_children: [],
      };
    },

    // Selectionne les lignes qui correspondent à la recherche sur le nom d'un expert
    handleSearch: function(row, col, cellValue, searchExpert) {
      if (!searchExpert) {
        return cellValue;
      }

      for (const [key, value] of Object.entries(row)) {
        if ((key == "expert_primary_adults" || key == "expert_primary_children") && value.id == searchExpert.id) {
          return cellValue;
        } else if (key == "expert_secondary_adults" || key == "expert_secondary_children") {
          value.forEach((element) => {
            if (element.id == searchExpert.id) return cellValue;
          });
        }
      }
    },
  },

  /**
   * Mounted
   */
  async mounted() {
    // Not for regular users
    if (this.user_is_regular) {
      this.$router.push("/dashboard");
    }

    //Init data
    let now = new Date();

    this.year = now.getFullYear();

    if (now.getMonth() < 6) {
      this.period = 1;
    } else {
      this.period = 2;
    }

    // Fetch data
    await this.getExperts();
    this.getAssignments();
  },
};
</script>
